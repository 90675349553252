import React from 'react'
import "../styles/modules.scss"
import "../styles/base.scss"
import { dateToLongString } from '../utilities/helpers'

interface ArticleProps {
    date?: Date
    title: String
    redirectUrl?: string
    shortDesc: String
    slug: String
}

const Article = (props: ArticleProps) => {
    const targetUrl = props.redirectUrl ? props.redirectUrl : `/insights${props.slug}.html`

    return(
        <div className="text-container mb-5 px-0 px-lg-5">
            <div>
                <h2 className="d-inline-block"><a className="article-title-link" href={targetUrl}>{props.title}</a></h2>
                {props.date ?
                    <p className="d-inline-block text-end ms-4"><i>{dateToLongString(props.date)}</i></p>
                    : null}
            </div>
            <p className="mb-4">{props.shortDesc}</p>
            <a href={targetUrl} className="btn btn-secondary text-primary">READ MORE</a>
        </div>
    )
}

export default Article