import dayjs from 'dayjs'

interface IArticle {
    id: string,
    title: string,
    slug: string,
    date?: Date,
    short_description: ShortDesc,
    tags?: Tag[]
}

interface ShortDesc {
    short_description: String
}

interface Tag {
    tagName: string,
    slug: string,
    id: string,
    articles?: IArticle[]
}

export function buildTagRelationships(articles: IArticle[]) {
    var tagsRecord: Record<string, Tag> = {}

    for (let article of articles) {
        if (article.tags != null)
        {
            for (let tag of article.tags) {
                if (tagsRecord[tag.slug] == null) {
                    tagsRecord[tag.slug] = {
                        tagName: tag.tagName,
                        slug: tag.slug,
                        id: tag.id,
                        articles: []
                    }
                }
    
                tagsRecord[tag.slug].articles?.push(article)
            }
        }
    }

    return tagsRecord
}

export function dateToLongString(date: Date) {
    return dayjs(date).format('MMMM D, YYYY')
}