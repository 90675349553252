import React from 'react'

const Subscribe = () => {
    return(
        <div className="subscribe-box bg-light p-3 mb-5">
            <h3 className="text-primary fw-bold">Want to receive the latest articles?</h3>
            <a href="/subscribe" className="btn btn-primary btn-block w-100">Subscribe</a>
        </div>
    )
}

export default Subscribe