import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import VideoPlaceholder from "../images/video-placeholder.jpg"

interface HeroVideoProps {
    title: String,
    body?: String,
    buttonLink?: String,
    buttonText?: String,
    video: String
}

const HeroVideo = (props: HeroVideoProps) => {
    return(
        <div className="hero hero-video">
            <Container className="hero-content-center">
                <Row>
                    <Col md="8" className="hero-content offset-md-2 py-5 text-center">
                        <h1 className="text-white">
                            {props.title}
                        </h1>
                        {props.body ? 
                            <h2 className="text-white mb-5">
                                {props.body}
                            </h2> 
                            : null}
                        {props.buttonLink ?
                            <a href={props.buttonLink.toString()} className="btn btn-secondary">
                                {props.buttonText}
                            </a>
                            : null}
                    </Col>
                </Row>
            </Container>
            <div className="background-container">
                <video autoPlay loop muted poster={VideoPlaceholder} height="100%">
                    <source src={props.video.toString()} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default HeroVideo