import { useStaticQuery, graphql } from "gatsby"
import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Article from "../components/article"
import HeroVideo from "../components/hero-video"
import Topics from "../components/topics"
import VideoPlaceholder from "../images/video-placeholder.jpg"
import Layout from "../layouts/layout"
import { buildTagRelationships } from "../utilities/helpers"

interface TagProps {
    data: {
        videoData: {
            hero: {
                heroImage: {
                    url: string
                }
            }
        }
    },
    pageContext: {
        slug: string,
        topic: Tag
        tagsRecord: Record<string, Tag>
    }
}

interface IArticle {
    id: string,
    title: string,
    slug: string,
    date?: Date,
    short_description: ShortDesc,
    tags: Tag[]
}

interface ShortDesc {
    short_description: String
}

interface Tag {
    tagName: string,
    slug: string,
    id: string,
    articles?: IArticle[]
}

const TagPage = (props: TagProps) => {

    const videoPage = props.data.videoData

    return(
        <Layout>
            <HeroVideo 
                title={props.pageContext.topic.tagName} 
                video={videoPage.hero.heroImage.url} 
                />
            <div className="section-container">
                <Container>
                    <Row>
                        <Topics tags={props.pageContext.tagsRecord} />
                        <Col size="12" lg="9">
                            {props.pageContext.topic.articles ? 
                                props.pageContext.topic.articles.map(article =>
                                    <Article key={`article-${article.id}`} date={article.date} title={article.title} shortDesc={article.short_description.short_description} slug={article.slug} />)
                                : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query TagQuery {
        videoData: contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
            hero {
                heroImage {
                  url
                }
            }
        }
    }
`

export default TagPage