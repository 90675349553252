import React, { useEffect } from 'react'
import { Col } from 'react-bootstrap'
import Subscribe from './subscribe'

interface IArticle {
    id: string,
    title: string,
    slug: string,
    date?: Date,
    short_description: ShortDesc,
    tags?: Tag[]
}

interface ShortDesc {
    short_description: String
}

interface Tag {
    tagName: string,
    slug: string,
    id: string,
    articles?: IArticle[]
}

interface TopicsProps {
    tags: Record<string, Tag>
}

const Topics = (props: TopicsProps) => {
    useEffect(() => {
        console.log(props.tags)
    })

    return(
        <Col size="12" lg="3">
            <h3 className="text-primary fw-bold">Topics</h3>
            <ul className="topics">
                {Object.entries(props.tags).map(item => 
                    <li key={`tag-${item[0]}`} className="py-2">
                        <a href={`/tags${item[1].slug}.html`} className="js-tag">{item[1].tagName}</a>
                    </li>
                    )}
            </ul>
            <Subscribe />
        </Col>
    )
}

export default Topics